/* @font-face {
  font-family: 'TradeGothic';
  src: 
    url('./assets/fonts/trade-gothic/TradeGothicLTStd.eot?#iefix') format('embedded-opentype'),  
    url('./assets/fonts/trade-gothic/TradeGothicLTStd.otf')  format('opentype'),
    url('./assets/fonts/trade-gothic/TradeGothicLTStd.woff') format('woff'), 
    url('./assets/fonts/trade-gothic/TradeGothicLTStd.ttf')  format('truetype'), 
    url('./assets/fonts/trade-gothic/TradeGothicLTStd.svg#TradeGothicLTStd-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TradeGothic';
  src: 
    url('./assets/fonts/trade-gothic/TradeGothicLTStd-Bold.eot?#iefix') format('embedded-opentype'),  
    url('./assets/fonts/trade-gothic/TradeGothicLTStd-Bold.otf')  format('opentype'),
    url('./assets/fonts/trade-gothic/TradeGothicLTStd-Bold.woff') format('woff'), 
    url('./assets/fonts/trade-gothic/TradeGothicLTStd-Bold.ttf')  format('truetype'), 
    url('./assets/fonts/trade-gothic/TradeGothicLTStd-Bold.svg#TradeGothicLTStd-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
} */

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;

  /* disable text selection */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: moz-none;
  -ms-user-select: none;
  user-select: none;
}
